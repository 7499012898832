import { createFeatureSelector, createSelector } from '@ngrx/store';

import { authFeatureKey, AuthState } from './auth.reducer';

const selectAuth = createFeatureSelector<AuthState>(authFeatureKey);

export class AuthSelectors {
	public static userTokenExpired = createSelector(
		selectAuth,
		(state: AuthState) => {
			if (!state.userToken) return false;

			const {
				userToken: { exp },
			} = state;
			if (!exp) return false;

			return AuthSelectors.isExpired(exp);
		},
	);

	public static gameTokenExpired = createSelector(
		selectAuth,
		(state: AuthState) => {
			if (!state.gameToken) return false;

			const {
				gameToken: { exp },
			} = state;
			if (!exp) return false;

			return AuthSelectors.isExpired(exp);
		},
	);

	private static isExpired(expirationDate: number): boolean {
		const expiryDate = new Date(0);
		expiryDate.setUTCSeconds(expirationDate);
		const today = new Date();

		return expiryDate < today;
	}
}
