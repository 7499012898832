import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Player } from '@api/models/player.entity';
import {
	TokenRefreshed,
	TokenUser,
} from '@bussiness/custom-models/auth.custom-models';
import {
	AuthActions,
	AuthConfirmUserSuccessAction,
	AuthSetTokensAction,
} from '@bussiness/store/features/auth/auth.actions';
import { AuthSelectors } from '@bussiness/store/features/auth/auth.selectors';
import { LocalStorageEnum } from '@core/services/storage/local-storage.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthFacade {
	// Actions
	public signInUserSuccessAction$: Observable<void> = this.actions$.pipe(
		ofType(AuthActions.signInUserSuccess),
	);

	public confirmUserSuccessAction$: Observable<AuthConfirmUserSuccessAction> =
		this.actions$.pipe(ofType(AuthActions.confirmUserSuccess));

	public loginUserSuccessAction$: Observable<void> = this.actions$.pipe(
		ofType(AuthActions.loginUserSuccess),
	);

	public loginGameSuccessAction$: Observable<void> = this.actions$.pipe(
		ofType(AuthActions.loginGameSuccess),
	);

	// Selectors
	public userTokenExpired$: Observable<boolean> = this.store.select(
		AuthSelectors.userTokenExpired,
	);

	public gameTokenExpired$: Observable<boolean> = this.store.select(
		AuthSelectors.gameTokenExpired,
	);

	constructor(
		private store: Store,
		private actions$: Actions,
	) {}

	public signUser(name: string, email: string, password: string): void {
		this.store.dispatch(AuthActions.signInUser({ name, email, password }));
	}

	public confirmUser(token: string): void {
		this.store.dispatch(AuthActions.confirmUser({ token }));
	}

	public loginUser(name: string, password: string): void {
		this.store.dispatch(AuthActions.loginUser({ name, password }));
	}

	public logoutUser(): void {
		this.store.dispatch(AuthActions.logoutUser());
	}

	public requestPasswordReset(email: string): void {
		this.store.dispatch(AuthActions.requestPasswordReset({ email }));
	}

	public checkTempPassword(email: string, code: string): void {
		this.store.dispatch(AuthActions.checkTempPassword({ email, code }));
	}

	public resetPassword(email: string, password: string): void {
		this.store.dispatch(AuthActions.resetPassword({ email, password }));
	}

	public refreshUserToken({ iat, exp }: TokenUser): void {
		this.store.dispatch(
			AuthActions.refreshUserSessionSuccess({ iat, exp }),
		);
	}

	public loginGame(_id: string, name: string, password?: string): void {
		this.store.dispatch(AuthActions.loginGame({ _id, name, password }));
	}

	public loginPlayer(player: Player): void {
		this.store.dispatch(AuthActions.loginPlayer({ player }));
	}

	public logoutPlayer(player: Player): void {
		this.store.dispatch(AuthActions.logoutPlayer({ player }));
	}

	public fastUserLogin(name: string, targetPath: string): void {
		this.store.dispatch(AuthActions.fastLoginUser({ name, targetPath }));
	}

	public refreshTokensSuccess(tokens: TokenRefreshed[]): void {
		const action: AuthSetTokensAction = {};

		for (const { type, value } of tokens) {
			if (type === LocalStorageEnum.UserToken) action.userToken = value;
			else if (type === LocalStorageEnum.GameToken)
				action.gameToken = value;
			else if (type === LocalStorageEnum.PlayerToken)
				action.playerToken = value;
		}

		this.store.dispatch(AuthActions.refreshTokensSuccess(action));
	}
}
