import { Player } from '@api/models/player.entity';
import { User } from '@api/models/user.entity';
import { JwtToken } from '@bussiness/custom-models/auth.custom-models';
import { createAction, props } from '@ngrx/store';

export interface AuthSendCredentialsAction {
	name: string;
	password: string;
}

export interface AuthSetTokenAction {
	iat: number;
	exp: number;
}

export interface AuthSetTokensAction {
	userToken?: JwtToken;
	gameToken?: JwtToken;
	playerToken?: JwtToken;
}

export interface AuthConfirmUserAction {
	token: string;
}

export interface AuthConfirmUserSuccessAction {
	user: User;
}

export interface AuthSignInUserAction {
	name: string;
	email: string;
	password?: string;
}

export interface AuthRequestPswResetAction {
	email: string;
}

export interface AuthCheckTempPswAction {
	email: string;
	code: string;
}

export interface AuthResetPswAction {
	email: string;
	password: string;
}

export interface AuthLoginGameAction {
	_id: string;
	name: string;
	password?: string;
}

export interface AuthLoginPlayerAction {
	player: Player;
}

export interface AuthFastLoginUserAction {
	name: string;
	targetPath: string;
}

export interface AuthFastLoginUserSuccessAction {
	targetPath: string;
}

export class AuthActions {
	public static getKey = createAction('[Auth] Get Key');
	public static loginUser = createAction(
		'[Auth] Login User',
		props<AuthSendCredentialsAction>(),
	);

	public static signInUser = createAction(
		'[Auth] Sign In User',
		props<AuthSignInUserAction>(),
	);

	public static signInUserSuccess = createAction(
		'[Auth] Sign In User Success',
	);

	public static confirmUser = createAction(
		'[Auth] Confirm User',
		props<AuthConfirmUserAction>(),
	);

	public static confirmUserSuccess = createAction(
		'[Auth] Confirm User Success',
		props<AuthConfirmUserSuccessAction>(),
	);

	public static loginUserSuccess = createAction(
		'[Auth] Login User Success',
		props<AuthSetTokenAction>(),
	);

	public static logoutUser = createAction('[Auth] Logout User');

	public static logoutUserSuccess = createAction(
		'[Auth] Logout User Success',
	);

	public static requestPasswordReset = createAction(
		'[Auth] Request Password Reset',
		props<AuthRequestPswResetAction>(),
	);

	public static refreshUserSessionSuccess = createAction(
		'[Auth] Refresh User Session Success',
		props<AuthSetTokenAction>(),
	);

	public static refreshTokensSuccess = createAction(
		'[Auth] Refresh Tokens Success',
		props<AuthSetTokensAction>(),
	);

	public static checkTempPassword = createAction(
		'[Auth] Check Temp Password',
		props<AuthCheckTempPswAction>(),
	);

	public static resetPassword = createAction(
		'[Auth] Reset Password',
		props<AuthResetPswAction>(),
	);

	public static loginGame = createAction(
		'[Auth] Login Game',
		props<AuthLoginGameAction>(),
	);

	public static loginGameSuccess = createAction(
		'[Auth] Login Game Success',
		props<AuthSetTokenAction>(),
	);

	public static loginPlayer = createAction(
		'[Auth] Login Player',
		props<AuthLoginPlayerAction>(),
	);

	public static loginPlayerSuccess = createAction(
		'[Auth] Login Player Success',
		props<AuthSetTokenAction>(),
	);

	public static logoutPlayer = createAction(
		'[Auth] Logout Player',
		props<AuthLoginPlayerAction>(),
	);

	public static fastLoginUser = createAction(
		'[Auth] Fast Login User',
		props<AuthFastLoginUserAction>(),
	);

	public static fastLoginUserSuccess = createAction(
		'[Auth] Fast Login User Sucess',
		props<AuthFastLoginUserSuccessAction>(),
	);

	public static refreshAllTokens = createAction('[Auth] Refresh All Tokens');
}
