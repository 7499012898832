import { JwtToken } from '@bussiness/custom-models/auth.custom-models';
import {
	AuthActions,
	AuthSetTokenAction,
	AuthSetTokensAction,
} from '@bussiness/store/features/auth/auth.actions';
import { Action, createReducer, on } from '@ngrx/store';

export const authFeatureKey = 'auth';

export interface AuthState {
	gameToken: JwtToken;
	playerToken: JwtToken;
	userToken: JwtToken;
}

export const initialState: AuthState = {
	gameToken: null,
	playerToken: null,
	userToken: null,
};

const updateUserTokenFunc = (
	state,
	{ iat, exp }: AuthSetTokenAction,
): AuthState => ({
	...state,
	userToken: {
		iat,
		exp,
	},
});

const logoutUserSuccessFunc = (state): AuthState => ({
	...state,
	userToken: {
		iat: null,
		exp: null,
	},
});

const updateGameTokenFunc = (
	state,
	{ iat, exp }: AuthSetTokenAction,
): AuthState => ({
	...state,
	gameToken: {
		iat,
		exp,
	},
});

const updatePlayerTokenFunc = (
	state,
	{ iat, exp }: AuthSetTokenAction,
): AuthState => ({
	...state,
	playerToken: {
		iat,
		exp,
	},
});

const updateTokensFunc = (
	state: AuthState,
	{ userToken, gameToken, playerToken }: AuthSetTokensAction,
): AuthState => ({
	...state,
	userToken: userToken ?? state.userToken,
	gameToken: gameToken ?? state.gameToken,
	playerToken: playerToken ?? state.playerToken,
});

const logoutPlayerFunc = (state): AuthState => ({
	...state,
	playerToken: null,
});

const reducer = createReducer(
	initialState,
	on(AuthActions.loginUserSuccess, updateUserTokenFunc),
	on(AuthActions.logoutUserSuccess, logoutUserSuccessFunc),
	on(AuthActions.loginGameSuccess, updateGameTokenFunc),
	on(AuthActions.refreshTokensSuccess, updateTokensFunc),
	on(AuthActions.loginPlayerSuccess, updatePlayerTokenFunc),
	on(AuthActions.logoutPlayer, logoutPlayerFunc),
);

export function authReducer(state = initialState, action: Action): AuthState {
	return reducer(state, action);
}
